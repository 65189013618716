// main yellow #FFC72A

html, body {
  background-color: #f7f4eb;
  font-family: 'IBM Plex Sans', sans-serif;
  margin: 0;
  height: 100%;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 50px;

  header {
    align-self: flex-start;
  }

  h1,
  h2,
  h3 {
    margin: 0;
  }

  h1 {
    color: #0500ad;
    font-size: 42px;
  }

  h3 {
    font-weight: 400;
  }

  section {
    display: flex;
    flex-direction: column;
  }

  .kaity {
    align-self: center;
    border-radius: 50%;
    height: 250px;
    margin-bottom: 16px;
    object-fit: cover;
    width: 250px;
  }

  .paragraph {
    font-size: 18px;
    line-height: 32px;

    .callout,
    a.link,
    a.link:visited {
      color: #0500ad;
      font-weight: 600;
    }

    &.experience {
      display: flex;
      flex-direction: column;
    }
  }

  .back-to-home {
    align-self: flex-start;
    margin-bottom: 16px;
  }

  article:not(:last-child) {
    margin-bottom: 16px;
  }

  .resume-header {
    font-size: 36px;
    margin-bottom: 16px;
  }

  .section {
    margin-bottom: 24px;
  }

  .education-section,
  .skill-section {
    width: 100%;
  }

  .org,
  .title,
  .date-range {
    display: flex;
    flex-direction: column;
  }

  .skill-items,
  .education {
    display: flex;
    flex-direction: column;

    article {
      width: 100%;
    }
  }

  .skill-items ul li {
    line-height: 32px;
  }

  ul {
    padding: 0 20px;
  }

  .emphasized {
    font-style: italic;
  }
}

@media (min-width: 1000px) {
  .container {
    max-width: 50%;

    .skill-items,
    .education {
      flex-direction: row;

      article {
        width: 50%;
      }
    }
  }
}